import {useNavigate} from "react-router-dom";
import React from "react";
import {Col, Content, Link, Paragraph, Row, Space, Title} from "../layout/Content";
import {InlineImage} from "../layout/Defaults";

export const Navigation = () => {
    const navigate = useNavigate();

    return (<Space justify={"space-between"} style={{width: "100%"}}>

            <Space Wrap>
                <Link className={window.location.pathname === "/" ? "active" : ""} href={"/"} onClick={()=>{
                    navigate("/");
                }}>Home</Link>
                <Link className={window.location.pathname === "/articles" ? "active" : ""} href={"/articles"} onClick={()=>{
                    navigate("/articles");
                }}>Articles</Link>
                <Link href={"#"}>Work</Link>
                <Link href={"#"}>APIs & Documentation</Link>
            </Space>
            <Space>
                <Link href={"#"}>Sign In</Link>
            </Space>
        </Space>);
}


export const Footer = () => {


    return (<footer className="app-footer">
        <Content>
            <Row>
                <Col xs={24}>
                    <div className="footer wide limits">

                        <Space justify={"space-between"}>
                            <div>
                        <Paragraph>Copyright 2014&mdash;{new Date().getFullYear()} <strong>OakFrame Interactive
                            Ltd.</strong></Paragraph>

                        <Space align={"center"} GapSm>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                 viewBox="0 0 512 512">
                                <path fill="#999"
                                      d="M156.3 41.88V222.6l-130.57 3c35.98 40.7 60.88 78.7 123.07 126 36.4-48.2 78.8-54.7 144.7 100.5l66.8 18c.7-49.4-15.2-97.8 126-155.3l-11.3-93-3-60.7-211.9-39.8V41.88z"/>
                            </svg>
                            <Paragraph>Created with <span style={{}}>love</span>. Forever, from Dallas,
                                TX</Paragraph></Space>
                            </div>
                            <div>
                                <Space GapSm>
                                    <Link href={"/privacy"} className={"Small"}>Privacy Policy</Link> <Paragraph Muted>|</Paragraph>
                                    <Link href={"/terms"} className={"Small"}>Terms of Service</Link> <Paragraph Muted>|</Paragraph>
                                    <Link href={"/cookies"} className={"Small"}>Cookie Policy</Link>
                                </Space>
                            </div>

                        </Space>
                    </div>
                </Col>
            </Row>
        </Content>
    </footer>);
}


export const Header = () => {


    return (<header className="app-header">
        <Row>
            <Col xs={24}>
                <div className={"wide limits"}>
        <Content>
            <Title><InlineImage src={"/asset/shape-transparent.png"}/> <strong>OakFrame</strong> <span className={"hide-sm"}>Interactive Ltd.</span></Title>
        </Content>
                </div></Col></Row>
    </header>);
}

