import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {Button, Col, Content, Input, Layout, Page, Row, Small, Space, Title} from "../layout/Content";
import {Breadcrumb, Result, Select, Skeleton, Tabs} from "../layout/Defaults";
import {Header} from "../partial/Navigation";
import {Footer} from "../partial/Navigation";

interface UserProfile {
    uuid: string;
    attributes: {
        locale: string;
        displayName: string;
        email: string;
        username: string;
    };
    createdAt: string;
}

const Contact: React.FC = () => {
    const {uuid} = useParams<{ uuid: string }>();

    const [user, setUser] = useState<UserProfile | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();


    return (
        <Layout className="landing-page">
            <Header/>

            <Page Grow>
                <Content style={{height: "100%"}}>
                    <div className={"limits wide pad"}>

                        <Row Fill>
                            <Col xs={24} md={12}>
                                <Space GapSm id="our-services">
                                    <h2>Get in Contact with Us</h2>
                                    <Space GapSm direction={"vertical"} Wide>
                                        <Space GapSm><Select
                                            placeholder={"Select Organization Type"}
                                            options={[
                                                {
                                                    element: <span>General</span>,
                                                    value: "consultation",
                                                    category: "Consultation"
                                                },
                                                {
                                                    element: <span>Startup</span>,
                                                    value: "startup-green",
                                                    category: "Greenfield"
                                                },
                                                {
                                                    element: <span>Small-to-Medium</span>,
                                                    value: "smb-green",
                                                    category: "Greenfield"
                                                },
                                                {
                                                    element: <span>Enterprise</span>,
                                                    value: "enterprise-green",
                                                    category: "Greenfield"
                                                },
                                            ]} onSelected={() => {
                                        }}
                                            categorize={{
                                                property: "category",
                                                order: ["Consultation", "Integration", "Greenfield"]
                                            }}
                                        /> <Select
                                            placeholder={"Product & Services"}
                                            options={[
                                                {
                                                    element: <span>Large Language Models</span>,
                                                    value: "ml",
                                                    category: "AI & ML"
                                                },
                                                {
                                                    element: <span>Consulting</span>,
                                                    value: "development-consulting",
                                                    category: "Research & Development"
                                                },
                                                {
                                                    element: <span>Microservices</span>,
                                                    value: "development-micro",
                                                    category: "Research & Development"
                                                },
                                                {
                                                    element: <span>API Endpoints</span>,
                                                    value: "development-endpoints",
                                                    category: "Products"
                                                },
                                            ]} onSelected={() => {
                                        }}
                                            categorize={{
                                                property: "category",
                                                //   order:["Consultation","Integration","Greenfield"]
                                            }}
                                        /> <Input Grow type={"text"} placeholder={"your name"}/></Space>
                                        <Input Grow type={"email"} placeholder={"email address"}/>
                                        <Space GapSm><input type={"checkbox"} id={"virtual-nda"}/> <label
                                            htmlFor={"virtual-nda"}>Enable Virtual-NDA<span
                                            className={"danger"}>*</span></label></Space>
                                        <textarea placeholder={"tell us about your project"}></textarea>
                                        <Button type={"primary"}>Submit</Button>
                                    </Space>

                                </Space>
                            </Col>

                        </Row>
                    </div>
                </Content>
            </Page>
            <Footer/>
        </Layout>
    );

};

export default Contact;