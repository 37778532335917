import {
    Col,
    Content,
    DropDown,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Spacer,
    Title,
    Divider,
    Button, Card, Input
} from "../layout/Content";
import React from "react";
import {Footer} from "../partial/Navigation";
import {Header} from "../partial/Navigation";


export function TermsOfService() {
    return (<Layout className="landing-page">
        <Header/>

        <Page Grow>
            <Content style={{height: "100%"}}>
                <div className={"limits wide pad"}>

                <Row>
                    <Col xs={24}>
                        <Space direction="vertical" Gap>
                    <Title Large>Terms of Service</Title>
                    <p>Welcome to OakFrame Interactive Ltd. By using our platform, you agree to the following terms and conditions:</p>
                    <ul>
                        <li>Users must be at least 18 years old to create an account and use our services.</li>
                        <li>Users retain ownership of the content they upload, but grant the OakFrame Interactive Ltd. a non-exclusive, royalty-free license to use, display, and distribute the content on our platform.</li>
                        <li>Users must not upload content that infringes on the rights of others, is illegal, or violates our community guidelines.</li>
                        <li>We reserve the right to remove any content or terminate any account that violates these terms.</li>
                        <li>We are not liable for any damages resulting from the use of our platform.</li>
                    </ul>
                        </Space></Col></Row>
                </div>
            </Content>
        </Page>
        <Footer/>
    </Layout>)
}